import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage';
import { AlertController } from '@ionic/angular';

@Injectable({
	providedIn: 'root'
})
export class LoginService {
	token: BehaviorSubject<any>;
	error: BehaviorSubject<any>;

	api = 'https://busseweb.com:9090/login';

	constructor(private http: HttpClient, private store: Storage, private router: Router, private alertController: AlertController) {
		this.prefetchToken()
		this.error = new BehaviorSubject<any>(null);		
	}

	prefetchToken(): void {
		this.store.get("token").then(token => {			
			this.token = new BehaviorSubject<string>(token)			
		})
	}

	login(email: string, password: string) {
		return this.http.post<any>(this.api, { email, password }).subscribe(
			(res) => {
				this.store.set('token', res.token);

				this.token.next(res.token);
				this.error.next(null);				

				this.router.navigate([ '/analysis' ]);
			},
			(error) => {
				this.error.next(error.error.message);
				this.presentAlert();
			}
		);
	}

	logout() {
		this.store.remove('token');
		this.token.next(null);
	}

	async presentAlert() {
		const alert = await this.alertController.create({
			header: 'Alert',
			subHeader: 'Failure to Login',
			message: this.error.value,
			buttons: [ 'OK' ]
		});

		await alert.present();
	}


}
