import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './services/auth.guard';

const routes: Routes = [
	{ path: '', redirectTo: 'home', pathMatch: 'full' },
	{ path: 'home', loadChildren: () => import('./home/home.module').then((m) => m.HomePageModule) },
	{
		path: 'analysis',
		canActivate: [ AuthGuard ],
		loadChildren: () => import('./pages/analysis/analysis.module').then((m) => m.AnalysisPageModule)
	},
	{
		path: 'login',
		loadChildren: () => import('./pages/login/login.module').then((m) => m.LoginPageModule)
	},
	{
		path: 'items',
		canActivate: [ AuthGuard ],
		loadChildren: () => import('./pages/items/items.module').then((m) => m.ItemsPageModule)
	},
	{
		path: 'customers',
		canActivate: [ AuthGuard ],
		loadChildren: () => import('./pages/customers/customers.module').then((m) => m.CustomersPageModule)
	},
	{
		path: 'item/:iid',
		canActivate: [ AuthGuard ],
		loadChildren: () => import('./pages/item/item.module').then((m) => m.ItemPageModule)
	},
	{
		path: 'customer/:cid',
		canActivate: [ AuthGuard ],
		loadChildren: () => import('./pages/customer/customer.module').then((m) => m.CustomerPageModule)
	},
	{
		path: '**',
		redirectTo: 'home'
	}
];

@NgModule({
	imports: [ RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }) ],
	exports: [ RouterModule ]
})
export class AppRoutingModule {}
